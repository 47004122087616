import FeatureSection from "./FeatureSection";
import Footer from "./Footer";
import Header from "./Header";
import TestimonialSection from "./TestimonialSection";

export default function Home(){
    return(
        <div>
            <Header/>
            <FeatureSection/>
            <TestimonialSection/>
            
        </div>
    )
}
// 